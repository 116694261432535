/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Button, Go, Grid, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 1.75rem 0 2rem;
`;

const ContentBlock = styled.div`
  grid-column: span 12 / span 12;
  position: relative;
`;

const Figure = styled.figure`
  width: 100%;
  position: relative;
  display: block;
`;

// const ButtonContainer = styled.section`
//   display: flex;
//   align-items: flex-end;
// `;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const CollectionCTA = ({
  data: { heading, body, button, image, imageAlignment }
}) => (
  <Wrapper>
    <Grid node="article">
      <ContentBlock
        css={css`
          order: ${imageAlignment === `left` ? `2` : `1`};
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;

          ${MEDIA_QUERIES?.desktop} {
            grid-column: span 8 / span 8;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            position: relative;
            white-space: pre-wrap;
            padding: 0.3rem 0 0 0;
            ${MEDIA_QUERIES?.desktop} {
              padding: 0 0 0 0.75rem;
            }
          `}
        >
          <h2
            css={css`
              text-align: center;
            `}
            className="h2"
          >
            {heading}
          </h2>

          <p className="b1">
            <span dangerouslySetInnerHTML={{ __html: body }} />
          </p>
        </div>

        <div
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2rem 0 0;
            ${MEDIA_QUERIES?.desktop} {
              padding: 0;
              align-items: flex-end;
              justify-content: flex-end;
            }
          `}
        >
          <Go to={button?.url?.url}>
            <Button
              _css={css`
                padding: 0 3rem;
              `}
              border="var(--color-black)"
              text={button?.url?.text}
            />
          </Go>
        </div>
      </ContentBlock>

      <ContentBlock
        css={css`
          order: ${imageAlignment === `left` ? `1` : `2`};

          ${MEDIA_QUERIES?.desktop} {
            grid-column: span 4 / span 4;
          }
        `}
      >
        <Figure>
          <Image image={image} />
        </Figure>
      </ContentBlock>
    </Grid>
  </Wrapper>
);

export const query = graphql`
  fragment CollectionCTAFragment on SanityCollectionCTA {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    body

    button {
      url {
        text
        url
      }
    }

    image {
      asset {
        gatsbyImageData(
          width: 2048
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageAlignment
  }
`;

export default CollectionCTA;
