/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Button, Go, Grid, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { GRID_GAP_REM } from "~components/_common/Grid/index.jsx";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 5.5rem 0 4rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0 4rem;
  }
`;

const ContentHeader = styled.header`
  grid-column: span 12 / span 12;
  position: relative;
  margin-bottom: 3rem;
  padding: 0 2rem;

  ${MEDIA_QUERIES?.desktop} {
    margin-bottom: 4rem;
    padding: 0;
  }
`;

const Heading = styled.h2`
  text-align: center;
`;

/** ============================================================================
 * @component
 * PressGrid containing a series of press items with button links.
 */
const PressGrid = ({
  data: { backgroundColor, fontColor, heading, subheading, pressItems }
}) => {
  // ---------------------------------------------------------------------------
  // variables

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper>
      <Grid>
        <ContentHeader>
          <Heading className="h2">{heading}</Heading>

          <Heading
            css={css`
              font-family: "Quadrant Text";
            `}
            className="h2"
            dangerouslySetInnerHTML={{ __html: subheading }}
          />
        </ContentHeader>

        {pressItems?.[0] &&
          pressItems.map((item) => {
            const key = item?.button?.url.url;

            return (
              <div
                key={key}
                css={css`
                  grid-column: span 12 / span 12;
                  padding-bottom: 100%;
                  position: relative;
                  margin-bottom: 3rem;
                  border: 1px solid black;
                  overflow-y: scroll;

                  ${MEDIA_QUERIES?.desktop} {
                    grid-column: span 4 / span 4;
                    overflow: hidden;
                    margin-bottom: ${GRID_GAP_REM}rem;
                  }
                `}
              >
                <article
                  css={[
                    css`
                      transition: 0.5s transform var(--cubic-easing);
                      transform: translate3d(0, 0%, 0);

                      position: absolute;
                      top: 0;
                      bottom: -100%;
                      left: 0;
                      right: 0;

                      ${MEDIA_QUERIES.desktop} {
                        &:hover {
                          transform: translate3d(0, -50%, 0);
                        }
                      }
                    `
                  ]}
                >
                  <figure
                    css={[
                      css`
                        width: 100%;
                        height: 50%;
                      `
                    ]}
                  >
                    <Image image={item.image} />
                  </figure>

                  <div
                    css={[
                      css`
                        width: 100%;
                        height: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      `
                    ]}
                  >
                    <Go to={item?.button?.url?.url} newTab>
                      <Button
                        _css={[
                          css`
                            width: 10rem;
                          `
                        ]}
                        border="black"
                        text={item?.button?.url?.text}
                      />
                    </Go>

                    <p
                      css={[
                        css`
                          position: absolute;
                          bottom: 1rem;
                        `
                      ]}
                      className="b2"
                    >
                      {item?.footnote}
                    </p>
                  </div>
                </article>
              </div>
            );
          })}
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment PressGridFragment on SanityPressGrid {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    subheading

    pressItems {
      image {
        altText

        asset {
          originalFilename
          title
          description

          gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
        }
      }
      button {
        url {
          text
          url
        }
      }
      footnote
    }
  }
`;

export default PressGrid;
