/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Button, Go, SquareImage } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${MEDIA_QUERIES?.desktop} {
    flex-direction: row;
    height: 50vw;
  }
`;

const ContentBlock = styled.article`
  width: 100%;
  position: relative;
  ${MEDIA_QUERIES?.desktop} {
    width: 50%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 20;
  padding: 0 0 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const CollectionBanner = ({
  data: { buttonLeft, imageLeft, buttonRight, imageRight }
}) => (
  <Wrapper>
    <ContentBlock>
      <SquareImage image={imageLeft} />

      <ButtonContainer>
        <Go to={buttonLeft?.url?.url}>
          <Button
            _css={css`
              width: 14rem;
            `}
            text={buttonLeft?.url?.text}
          />
        </Go>
      </ButtonContainer>
    </ContentBlock>

    <ContentBlock>
      <SquareImage image={imageRight} />

      <ButtonContainer>
        <Go to={buttonRight?.url?.url}>
          <Button
            _css={css`
              width: 14rem;
            `}
            text={buttonRight?.url?.text}
          />
        </Go>
      </ButtonContainer>
    </ContentBlock>
  </Wrapper>
);

export const query = graphql`
  fragment CollectionBannerFragment on SanityCollectionBanner {
    _type

    backgroundColor {
      hex
      title
    }

    buttonLeft {
      url {
        text
        url
      }
    }

    imageLeft {
      asset {
        gatsbyImageData(
          width: 1024
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    buttonRight {
      url {
        text
        url
      }
    }

    imageRight {
      asset {
        gatsbyImageData(
          width: 1024
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default CollectionBanner;
