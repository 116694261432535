/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Grid, Go, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 2.5rem 0 6rem;
  ${MEDIA_QUERIES?.desktop} {
    padding: 5rem 0 6rem;
  }
`;

const RotatedContainer = styled.div`
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 2rem;
  ${MEDIA_QUERIES?.desktop} {
    position: relative;
    left: auto;
    top: auto;
  }
`;

const RotatedText = styled.p`
  transform: rotate(90deg);
`;

const Logos = styled.div`
  width: calc(100% - 3rem);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
  ${MEDIA_QUERIES?.desktop} {
    flex-direction: row;
    gap: 0;
  }
`;

const Figure = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 5%;
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const PressMarquee = ({ data: { heading, logos, logoLinks } }) => {
  const [hoverIndex, setHoverIndex] = useState(null);

  //

  return (
    <Wrapper>
      <Grid>
        <div
          css={css`
            grid-column: span 12 / span 12;
            position: relative;
            display: flex;
            justify-content: center;

            ${MEDIA_QUERIES?.desktop} {
              justify-content: flex-start;
            }
          `}
        >
          <RotatedContainer>
            <RotatedText className="b2">{heading}</RotatedText>
          </RotatedContainer>

          <Logos>
            {logoLinks.map(({ logo, url }, logoIndex) => {
              const hovering = hoverIndex !== null;

              let active = true;

              if (hovering) {
                active = logoIndex === hoverIndex;
              }

              return (
                <Go
                  to={url}
                  _css={css`
                    width: 66.6%;
                    height: 100%;
                    position: relative;
                    padding: 0 5%;

                    ${MEDIA_QUERIES?.desktop} {
                      width: 20%;
                    }
                  `}
                >
                  <Figure
                    css={css`
                      transition: 0.3s var(--cubic-easing) opacity;

                      opacity: ${active ? `1` : `0.3`};
                    `}
                    onMouseEnter={() => setHoverIndex(logoIndex)}
                    onMouseLeave={() => setHoverIndex(null)}
                  >
                    <Image
                      _css={css`
                        width: 100%;
                        position: relative;
                        display: block;
                        pointer-events: none;
                      `}
                      image={logo}
                    />
                  </Figure>
                </Go>
              );
            })}
          </Logos>
        </div>
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment PressMarqueeFragment on SanityPressMarquee {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    logos {
      asset {
        gatsbyImageData(
          width: 256
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    logoLinks {
      logo {
        asset {
          gatsbyImageData(
            width: 256
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }

      url
    }
  }
`;

export default PressMarquee;
