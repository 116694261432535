/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 0.5rem 0 1.5rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 1.75rem 0 2rem;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const SmallText = ({ data: { body } }) => (
  <Wrapper>
    <Grid>
      <div
        css={css`
          grid-column: span 12 / span 12;
        `}
      >
        <p className="b1">{body}</p>
      </div>
    </Grid>
  </Wrapper>
);

export const query = graphql`
  fragment SmallTextFragment on SanitySmallText {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    body
  }
`;

export default SmallText;
