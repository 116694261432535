/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  height: var(--browser-height);
  position: relative;

  ${MEDIA_QUERIES?.desktop} {
    aspect-ratio: 2.25;
    height: auto;
  }
`;

const Figure = styled.figure`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ImageCSS = css`
  width: 100%;
  height: 100vh;
  position: relative;
  display: block;

  ${MEDIA_QUERIES?.desktop} {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving a full-width PageBuilder image.
 */
const ImageBanner = ({ data: { animateText, fontColor, image, text } }) => {
  const ref = useRef();
  const textRef = useRef();

  const [translate, setTranslate] = useState({
    x: 0,
    y: 0
  });

  useEffect(() => {
    if (!ref?.current || !textRef?.current) {
      return;
    }

    const x = ref.current.getBoundingClientRect().width;
    const y = ref.current.getBoundingClientRect().height;

    const { width, height } = textRef.current.getBoundingClientRect();

    setTranslate({
      x: x - width,
      y: y - height
    });
  }, [ref, textRef]);

  return (
    <Wrapper ref={ref}>
      <Figure>
        <Image _css={ImageCSS} image={image} />
      </Figure>

      {text && text !== `` && (
        <div
          css={css`
            width: 100%;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding-bottom: 1rem;
            z-index: 20;
            text-align: center;

            ${MEDIA_QUERIES?.desktop} {
              display: none;
            }

            p {
              color: ${fontColor?.hex};
            }
          `}
        >
          <p className="b1">{text}</p>
        </div>
      )}

      {text && text !== `` && (
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;
            display: none;

            p {
              color: ${fontColor?.hex};
            }

            //

            @keyframes move-text {
              0% {
                transform: translate3d(0, 0, 0);
              }
              25% {
                transform: translate3d(0, ${translate.y}px, 0);
              }
              50% {
                transform: translate3d(${translate.x}px, ${translate.y}px, 0);
              }
              75% {
                transform: translate3d(${translate.x}px, 0, 0);
              }
              100% {
                transform: translate3d(0, 0, 0);
              }
            }

            ${MEDIA_QUERIES?.desktop} {
              display: block;

              ${animateText === true &&
              `animation: 20s move-text linear infinite;`}
            }
          `}
        >
          <div
            css={css`
              position: absolute;
              transform: translate3d(0, 0, 0);
            `}
          >
            <p
              ref={textRef}
              css={css`
                grid-column: span 12 / span 12;
                padding-bottom: 1rem;
                text-align: center;

                ${MEDIA_QUERIES?.desktop} {
                  text-align: left;
                }
              `}
              className="b1"
            >
              {text}
            </p>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export const query = graphql`
  fragment ImageBannerFragment on SanityImageBanner {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    text
    animateText

    image {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

// imageXS

export default ImageBanner;
