/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 5rem 0 2rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 14rem 0 3rem;
  }
`;

const Heading = styled.h3`
  grid-column: span 12 / span 12;
  grid-column-start: 1;
  text-align: center;
  position: relative;
  margin-top: 0.75rem;
  line-height: 22px;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 8 / span 8;
    grid-column-start: 3;
    margin-top: 0;
    line-height: 22px;
  }
`;

const Body = styled.p`
  grid-column: span 12 / span 12;
  grid-column-start: 1;
  position: relative;
  white-space: pre-wrap;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 6 / span 6;
    grid-column-start: 4;
  }
`;

const Form = styled.form`
  grid-column: span 12 / span 12;
  grid-column-start: 1;
  position: relative;
  padding: 2.5rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 6 / span 6;
    grid-column-start: 4;
  }
`;

const FormInput = styled.input`
  width: 100%;
  height: 36px;
  position: relative;
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  margin: 0 0 0.5rem;

  ${MEDIA_QUERIES?.desktop} {
    height: 42px;
    margin: 0 0 0.5rem;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  height: 7rem;
  border: 0px;
  border-bottom: 1px solid black;
  padding: 0 0 6px;
  margin: 0 0 1rem;
  resize: none;

  ${MEDIA_QUERIES?.desktop} {
    margin: 0.75rem 0 1rem;
  }
`;

const FormButton = styled.button`
  width: 223px;
  height: 36px;
  position: relative;
  display: block;
  margin-top: 1.75rem;
  border: 1px solid black;
  border-radius: 2rem;

  ${MEDIA_QUERIES?.hoverable} {
    width: 150px;
    height: 44px;
    margin-top: 0.4rem;
    border-radius: 100px;

    &:hover {
      background: var(--color-mud);
      border: 1px solid transparent;
    }
  }
`;

/** ============================================================================
 * @component
 * Component that renders a submittable form with heading & body
 */
const ContactForm = ({
  data: { backgroundColor, fontColor, heading, body }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState({
    submitting: false,
    submitted: false
  });

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (status?.submitting || status?.submitted) {
      return false;
    }

    setStatus({
      submitted: false,
      submitting: true
    });

    //

    fetch(`/api/sendgrid`, {
      method: `post`,
      headers: {
        "content-type": `application/json`
      },
      body: JSON.stringify(formData)
    }).then((res) => {
      setStatus({
        submitted: true,
        submitting: false
      });
    });

    // fetch(`/api/gmail`, {
    //   method: `post`,
    //   headers: {
    //     "content-type": `application/json`
    //   },
    //   body: JSON.stringify(formData)
    // }).then((res) => {
    //   setStatus({
    //     submitted: true,
    //     submitting: false
    //   });
    // });

    return false;
  };

  // ---------------------------------------------------------------------------
  // render

  let submitText = `Submit`;

  if (status?.submitting) {
    submitText = `Working...`;
  } else if (status?.submitted) {
    submitText = `Thank you!`;
  }

  return (
    <Wrapper
      css={[
        css`
          transition: 0.3s ease opacity;

          opacity: ${status?.submitting || status?.submitted ? 0.25 : 1};
          pointer-events: ${status?.submitting || status?.submitted
            ? `none`
            : `auto`};

          background-color: ${backgroundColor?.hex};
          color: ${fontColor?.hex};
        `
      ]}
    >
      <Grid>
        <Heading className="h4">{heading}</Heading>

        <Body className="b2" dangerouslySetInnerHTML={{ __html: body }} />

        <Form onSubmit={handleSubmit}>
          <FormInput
            name="name"
            className="b3"
            onChange={onChange}
            placeholder="Name*"
            readOnly={status?.submitting || status?.submitted}
            required
          />

          <FormInput
            name="email"
            className="b3"
            type="email"
            onChange={onChange}
            placeholder="Email*"
            readOnly={status?.submitting || status?.submitted}
            required
          />

          <FormInput
            name="subject"
            className="b3"
            onChange={onChange}
            placeholder="Subject*"
            readOnly={status?.submitting || status?.submitted}
            required
          />

          <FormTextArea
            name="message"
            className="b3"
            onChange={onChange}
            placeholder="Message*"
            readOnly={status?.submitting || status?.submitted}
            required
          />

          <FormButton className="text-button" type="submit">
            {submitText}
          </FormButton>
        </Form>
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ContactFormFragment on SanityContactForm {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    body
  }
`;

export default ContactForm;
