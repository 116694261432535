/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Button, Go, Grid, SquareImage } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { useWindowDimensions } from "~hooks";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 1rem 0 2rem;
  ${MEDIA_QUERIES?.desktop} {
    padding: 1.75rem 0 2rem;
  }
`;

const ContentBlock = styled.div`
  grid-column: span 12 / span 12;
  position: relative;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 6 / span 6;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const ImageLink = ({ data: { button, image } }) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <Wrapper>
      <Grid node="article">
        {!isDesktop && (
          <ContentBlock>
            <SquareImage image={image} />
          </ContentBlock>
        )}
        <ContentBlock
          css={css`
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 2rem 0;
            ${MEDIA_QUERIES?.desktop} {
              padding: 0;
              justify-content: flex-start;
            }
          `}
        >
          <Go to={button?.url?.url}>
            <Button
              _css={css`
                padding: 0 3rem;
              `}
              border="var(--color-black)"
              text={button?.url?.text}
            />
          </Go>
        </ContentBlock>

        {isDesktop && (
          <ContentBlock>
            <SquareImage image={image} />
          </ContentBlock>
        )}
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ImageLinkFragment on SanityImageLink {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    button {
      url {
        text
        url
      }
    }

    image {
      asset {
        gatsbyImageData(
          width: 2048
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default ImageLink;
