/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Button, Go, Grid, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 1rem 0 4rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 1.75rem 0 2rem;
  }
`;

const ContentHeader = styled.header`
  grid-column: span 12 / span 12;
  position: relative;
  padding: 0 5rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 0;
  }

  .h1 {
    line-height: 26px;

    ${MEDIA_QUERIES?.desktop} {
      line-height: 38px;
    }
  }
`;

const Content = styled.div`
  grid-column: span 12 / span 12;
  position: relative;
  padding: 1rem 0 2rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 1.5rem 0 2rem;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0.75rem;
  text-align: center;

  ${MEDIA_QUERIES?.desktop} {
    margin-bottom: 4rem;
  }
`;

const Figure = styled.figure`
  grid-column: span 12 / span 12;
  position: relative;
  display: block;
`;

const Subheading = styled.h3`
  text-align: center;
`;

const ButtonContainer = styled.div`
  grid-column: span 12 / span 12;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 1rem;

  ${MEDIA_QUERIES?.desktop} {
    padding: 3rem 0 3rem;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const ArticleBanner = ({
  data: { heading, subheading, body, button, image, imageLink }
}) => (
  <Wrapper>
    <Grid node="article">
      <ContentHeader>
        <Heading className="h1">
          <span dangerouslySetInnerHTML={{ __html: heading }} />
        </Heading>
      </ContentHeader>

      <Go
        to={imageLink}
        _css={css`
          grid-column: span 12;
        `}
      >
        <Figure>
          <Image
            _css={[
              css`
                width: 100%;
                max-width: none;
                height: 100%;
                min-height: 18rem;
                object-fit: cover;
              `
            ]}
            image={image}
          />
        </Figure>
      </Go>

      <Content>
        <Subheading className="h2">
          <span dangerouslySetInnerHTML={{ __html: subheading }} />
        </Subheading>

        <p className="b1">{body}</p>
      </Content>

      <ButtonContainer>
        <Go to={button?.url?.url}>
          <Button
            _css={css`
              padding: 0 2.75rem;
            `}
            border="var(--color-black)"
            text={button?.url?.text}
          />
        </Go>
      </ButtonContainer>
    </Grid>
  </Wrapper>
);

export const query = graphql`
  fragment ArticleBannerFragment on SanityArticleBanner {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    subheading
    body

    button {
      url {
        text
        url
      }
    }

    imageLink

    image {
      asset {
        gatsbyImageData(
          width: 2048
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default ArticleBanner;
