/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { useWindowDimensions } from "~hooks";
import { Grid, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import {
  GRID_GAP_REM,
  GRID_PADDING_REM
} from "~components/_common/Grid/index.jsx";

const Wrapper = styled.section`
  width: 100vw;
  position: relative;
  overflow: hidden;
`;

const FormBackground = styled.div`
  width: calc(100% + ${GRID_PADDING_REM}rem);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;

  ${MEDIA_QUERIES?.desktop} {
    width: calc(100% + ${GRID_PADDING_REM}rem + ${GRID_GAP_REM}rem);
  }
`;

const FormContent = styled.div`
  height: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.25rem 0 0;

  ${MEDIA_QUERIES?.desktop} {
    padding: 1rem 0 1.75rem;
  }
`;

const FormInput = styled.input`
  width: 100%;
  height: 2.25rem;
  position: relative;
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  -webkit-appearance: none;

  &::-webkit-input-placeholder {
    color: var(--color-black);
  }

  &::-moz-placeholder {
    color: var(--color-black);
  }

  &:-ms-input-placeholder {
    color: var(--color-black);
  }

  &:-moz-placeholder {
    color: var(--color-black);
  }

  ${MEDIA_QUERIES?.desktop} {
    height: 2.5rem;
  }
`;

const FormSubmitContainer = styled.div`
  height: 2.25rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  ${MEDIA_QUERIES?.desktop} {
    height: 2.5rem;
  }
`;

const FormSubmit = styled.input`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  background: transparent;
  cursor: pointer;
  border: none;
  color: black;
`;

const Figure = styled.figure`
  width: 100%;
  position: relative;

  ${MEDIA_QUERIES?.desktop} {
    width: calc(100% + ${GRID_PADDING_REM}rem);
  }
`;

const ContentBlock = styled.article`
  grid-column: span 12 / span 12;
  position: relative;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 6 / span 6;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const Newsletter = ({
  data: { backgroundColor, heading, body, image, imageAlignment }
}) => {
  const { isDesktop } = useWindowDimensions();

  const [form, setForm] = useState({});
  const [status, setStatus] = useState({
    submitting: false,
    submitted: false
  });

  const onChange = (e) => {
    setForm({
      ...form,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (status?.submitting || status?.submitted) {
      return false;
    }

    const formData = { listId: `X6XEat`, ...form };

    setStatus({
      submitting: true,
      submitted: false
    });

    const response = await fetch(`/api/klaviyo`, {
      body: JSON.stringify(formData),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
      });

    setStatus({
      submitting: false,
      submitted: true
    });

    return false;
  };

  return (
    <Wrapper>
      <Grid>
        <ContentBlock
          css={css`
            transition: 0.3s ease opacity;

            opacity: ${status?.submitting || status?.submitted ? 0.5 : 1};
            pointer-events: ${status?.submitting || status?.submitted
              ? `none`
              : `auto`};

            order: ${imageAlignment === `left` ? `2` : `1`};
            padding: 0;

            ${MEDIA_QUERIES?.desktop} {
              padding: 0 1.25rem 0 0;
            }
          `}
        >
          <FormBackground
            css={css`
              left: -${GRID_PADDING_REM / 2}rem;
              background: ${backgroundColor?.hex};

              ${MEDIA_QUERIES?.desktop} {
                left: -${GRID_PADDING_REM + GRID_GAP_REM / 2}rem;
              }
            `}
          />

          <FormContent>
            <header>
              <h2
                css={css`
                  text-align: center;
                `}
                className="h3"
              >
                {heading}
              </h2>

              <div
                css={css`
                  margin-top: -0.5rem;

                  ${MEDIA_QUERIES?.desktop} {
                    margin-top: 0;
                  }
                `}
              >
                {(!status?.submitting && !status?.submitted && (
                  <p className="b1">{body}</p>
                )) || (
                  <p className="b1">
                    {status?.submitting ? `Submitting...` : `Thank you.`}
                  </p>
                )}
              </div>
            </header>

            <form
              css={css`
                position: relative;
                margin: 14rem 0 0.75rem;

                ${MEDIA_QUERIES?.desktop} {
                  margin: 0;
                }
              `}
              onSubmit={onSubmit}
            >
              <FormInput
                className={isDesktop ? `b2` : `b3`}
                name="email"
                onChange={onChange}
                readOnly={status?.submitting || status?.submitted}
                placeholder="Enter e-mail address"
                type="email"
              />

              <FormSubmitContainer>
                <FormSubmit className={isDesktop ? `b2` : `b3`} type="submit" />
              </FormSubmitContainer>
            </form>
          </FormContent>
        </ContentBlock>

        <ContentBlock
          css={css`
            width: calc(100% + ${GRID_PADDING_REM}rem);
            left: calc(-${GRID_PADDING_REM}rem / 2);
            order: 0;

            ${MEDIA_QUERIES?.desktop} {
              order: ${imageAlignment === `left` ? `1` : `2`};
              width: calc(100% + ${GRID_GAP_REM / 2}rem);
              left: auto;
              right: ${GRID_GAP_REM / 2}rem;
            }
          `}
        >
          <Figure>
            <Image image={image} />
          </Figure>
        </ContentBlock>
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment NewsletterFragment on SanityNewsletter {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    heading
    body

    image {
      asset {
        gatsbyImageData(
          width: 2048
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageAlignment
  }
`;

export default Newsletter;
