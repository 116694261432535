/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;

  position: relative;
`;

const Figure = styled.figure`
  width: 100%;

  position: relative;
  overflow: hidden;
`;

const ImageCSS = css`
  width: 100%;
  height: 110vw;
  position: relative;
  display: block;
  ${MEDIA_QUERIES?.desktop} {
    height: auto;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving a full-width PageBuilder image.
 */
const FullImage = ({ data: { image } }) => (
  <Wrapper>
    <Figure>
      <Image _css={ImageCSS} image={image} />
    </Figure>
  </Wrapper>
);

export const query = graphql`
  fragment FullImageFragment on SanityFullImage {
    _type

    backgroundColor {
      hex
      title
    }

    image {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default FullImage;
