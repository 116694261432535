/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { useFAQs } from "~hooks";
import { ExpanderArea } from "~components";

/** ============================================================================
 * @component
 * FAQ block that shows content from a separate FAQ document.
 */
const AutoFAQ = ({ data: { category } }) => {
  // ---------------------------------------------------------------------------
  // variables

  const { edges } = useFAQs();

  let content;

  if (edges?.[0]) {
    edges.forEach(({ node }) => {
      if (
        !content &&
        node?.category?.title?.toLowerCase() === category?.title?.toLowerCase()
      ) {
        content = node;
      }
    });
  }

  if (!content?.questions?.[0]) {
    return <></>;
  }

  const items = content.questions.map(({ question, answer }) => ({
    label: question,
    body: answer
  }));

  const expanderData = {
    ...content,
    items
  };

  // ---------------------------------------------------------------------------
  // render

  return <ExpanderArea data={expanderData} />;
};

export const query = graphql`
  fragment AutoFAQFragment on SanityAutoFAQ {
    _type

    category {
      title
    }
  }
`;

export default AutoFAQ;
