import React from "react";
import { graphql } from "gatsby";
import { ExpanderArea } from "~components";

const Accordion = ({ data }) => <ExpanderArea data={data} />;

export default Accordion;

export const query = graphql`
  fragment AccordionFragment on SanityAccordion {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    heading
    text

    items {
      label
      body
      tags

      image {
        asset {
          gatsbyImageData(
            width: 2048
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }

    image {
      asset {
        gatsbyImageData(
          width: 2048
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    imageAlignment
  }
`;
