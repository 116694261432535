/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { Grid, PortableText } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 5rem 0 0rem;
  ${MEDIA_QUERIES?.desktop} {
    padding: 8rem 0 2rem;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const RichText = ({ data: { _rawText } }) => (
  <Wrapper>
    <Grid>
      <div
        css={[
          css`
            grid-column: span 12 / span 12;
            grid-column-start: 1;
            position: relative;

            ${MEDIA_QUERIES?.desktop} {
              grid-column: span 6 / span 6;
              grid-column-start: 4;
            }

            a {
              text-decoration: underline;
            }
          `
        ]}
      >
        <PortableText blocks={_rawText} />
      </div>
    </Grid>
  </Wrapper>
);

export const query = graphql`
  fragment RichTextFragment on SanityRichText {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    _rawText
  }
`;

export default RichText;
