/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { useQueryParam, StringParam } from "use-query-params";
import { ArticleCard, Go, Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 5rem 0 3rem;
  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0 4rem;
  }
`;

const ContentHeader = styled.header`
  grid-column: span 10 / span 10;
  grid-column-start: 2;
  position: relative;
  margin-bottom: 3rem;

  ${MEDIA_QUERIES?.desktop} {
    grid-column: span 12 / span 12;
    grid-column-start: 1;
    margin-bottom: 6rem;
  }
`;

const Heading = styled.h2`
  text-align: center;
`;

/** ============================================================================
 * @component
 * ArticleGrid containing ArticleCards from the magazine.
 */
const ArticleGrid = ({ data: { articles, heading, subheading } }) => {
  // ---------------------------------------------------------------------------
  // variables

  const [urlTag] = useQueryParam(`tag`, StringParam);

  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState(urlTag);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setActiveTag(urlTag);
  }, [urlTag]);

  useEffect(() => {
    if (!articles?.[0]) {
      return;
    }

    const parsedTags = [];

    articles.forEach((article) => {
      if (!article?.tags?.[0]) {
        return;
      }

      article.tags.forEach((tag) => {
        if (parsedTags?.includes(tag.trim())) {
          return;
        }

        parsedTags.push(tag.trim());
      });
    });

    setTags(parsedTags.sort());
  }, [articles]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper>
      <Grid node="article">
        <ContentHeader>
          <button
            css={css`
              display: block;
              position: relative;
              margin: 0 auto;
            `}
            type="button"
            onClick={() => window.location.reload()}
          >
            <Heading className="h2">{heading}</Heading>
          </button>

          <Heading
            css={css`
              font-family: "Quadrant Text";
            `}
            className="h2"
          >
            {subheading}
          </Heading>

          {tags?.[0] && (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.75rem;
                text-align: center;

                ${MEDIA_QUERIES?.desktop} {
                  margin-top: 1.5rem;
                }
              `}
            >
              <p className="b3">Filter:</p>

              {tags.map((tag, tagIndex) => {
                const isActive = tag === activeTag;

                //

                return (
                  <button
                    type="button"
                    key={`magazine-tag-${tag}`}
                    css={css`
                      margin: 0 0.25rem;

                      ${MEDIA_QUERIES?.hoverable} {
                        &:hover {
                          span {
                            font-style: italic;
                          }
                        }
                      }

                      span {
                        font-style: ${isActive ? `italic` : `normal`};
                      }
                    `}
                    onClick={() => setActiveTag(isActive ? null : tag)}
                  >
                    <p className="b3">
                      <span>{tag}</span>
                      {tagIndex < tags?.length - 1 && <span>,</span>}
                    </p>
                  </button>
                );
              })}
            </div>
          )}
        </ContentHeader>

        {articles?.[0] &&
          articles.map((article) => {
            const key = article?.handle;

            const matchesTag = !activeTag || article?.tags?.includes(activeTag);

            if (!matchesTag) {
              return <React.Fragment key={key} />;
            }

            return (
              <div
                key={key}
                css={css`
                  grid-column: span 12 / span 12;
                  position: relative;
                  margin-bottom: 1rem;

                  ${MEDIA_QUERIES?.desktop} {
                    grid-column: span 4 / span 4;
                    margin-bottom: 3rem;
                  }
                `}
              >
                <ArticleCard article={article} />
              </div>
            );
          })}
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ArticleGridFragment on SanityArticleGrid {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    subheading

    articles {
      slug {
        current
      }

      title
      formattedTitle
      excerpt
      tags

      cardImage {
        asset {
          gatsbyImageData(width: 1024, placeholder: NONE)
        }
      }
    }
  }
`;

export default ArticleGrid;
